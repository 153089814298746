import React from "react";
import useTranslations from "../../../components/useTranslations";
import Footer from "../../../components/Footer/Footer";
import ExhibitionLeftNav from "../../../components/TwoColumnLeftNav/NewsLeftNav";
import LocalizedLink from "../../../components/localizedLink";

const Exhibition = () => {
	// useTranslations is aware of the global context (and therefore also "locale")
	// so it'll automatically give back the right translations
	const { exhibition } = useTranslations();

	const OfficeJaInfo = () => {
		return (
			<>
				<div className="exhibition-page two-column-left-nav">
					<div className="main-content">
						<div className="content-container">
							<h1 className="exhibition_h1">{exhibition}</h1>					
							<div className="exhibition_inner">





								<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="https://www.foomajapan.jp/"
											className="exhibition_eventname_link"
										>
											FOOMA JAPAN 2022（国際食品工業展）
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/" class="btn_gray">
											ご質問・ご招待券はこちら
										</LocalizedLink>
									</div>
								</div>
								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">名称：</div>
												<div className="exhibition-li-content medium">
													<a
														href="https://www.foomajapan.jp/"
														className="exhibition-li-content medium"
													>
														FOOMA JAPAN 2022（国際食品工業展）
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会期：</div>
												<div className="exhibition-li-content medium">
													2022年6月7日（火）～2022年6月10日（金）
													<br />
													4日間　10：00～17：00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">主催：</div>
												<div className="exhibition-li-content medium">
													一般財団法人 日本食品機械工業会
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会場：</div>
												<div className="exhibition-li-content medium">
													東京ビックサイト　東1～8ホール
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">弊社小間：</div>
												<div className="exhibition-li-content medium">
													1K-20
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													主な出展商品：
												</div>
												<div className="exhibition-li-content medium">
												HACCP機能付防水型デジタル温度計、防水型無線温度ロガー、高精度デジタル温度計、中心温度計、CO2モニター、糖度計、タイマー　など
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">入場料：</div>
												<div className="exhibition-li-content medium">
													無料
													※完全事前登録制。事前に主催者公式webサイトにて登録、入手するクイックパスを持参ください。
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
												食品の生産現場から流通、店舗までに使用する食品機械・資材・備品関連商品の総合展示会です。
												<br />
												食品に携わる方々にたいへん人気のある本展示会に今年も出展いたします。
												<br />
												今回、調理時に測定した温度データをBluetooth通信でパソコン等端末に送信する機能付のハンディ型温度計『SK-270WP-B』等中心温度計や新型コロナウイルス対策に換気タイミングの目安となる二酸化炭素の濃度を測定するCO2モニター『SK-50CTH』等、様々な現場でお役に立てる製品を展示いたします。
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support" class="btn_blue">
										ご質問・ご招待券はこちら
									</LocalizedLink>
								</div>
							
								<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="http://miruhakaru.jp/"
											className="exhibition_eventname_link"
										>
											東海サイエンスパーク2022
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/" class="btn_gray">
											ご質問・ご招待券はこちら
										</LocalizedLink>
									</div>
								</div>

								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">名称：</div>
												<div className="exhibition-li-content medium">
													<a
														href="http://miruhakaru.jp/"
														className="exhibition-li-content medium"
													>
														東海サイエンスパーク2022
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会期：</div>
												<div className="exhibition-li-content medium">
													2022年6月2日（木）～2022年6月4日（土）
													<br />
													3日間　10：00～17：00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">主催：</div>
												<div className="exhibition-li-content medium">
												東海科学機器協会／（一社）愛知県計量連合会／産経新聞社
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">会場：</div>
												<div className="exhibition-li-content medium">
													名古屋国際会議場　イベントホール
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">弊社小間：</div>
												<div className="exhibition-li-content medium">
													34
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													主な出展商品：
												</div>
												<div className="exhibition-li-content medium">
												デジタル温度・温湿度計、無線式データロガー、高精度2ch温度ロガー、CO2モニター、放射温度計、pH計　など
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">入場料：</div>
												<div className="exhibition-li-content medium">
													無料
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
											    研究開発支援機器の総合展示会ですが、土曜日は、平日ご来場いただけないユーザー様、ディーラーの方々がお子様連れでご来場いただけるよう、親子で科学を楽しんで学べるための企画をご用意した展示会となっております。
												<br />
												今回弊社では、新製品の高精度2chロガー(JIS A級・4線式採用)や、感染症対策に換気のタイミングをわかりやすく知らせるCO2モニター、防水型無線温度計(Bluetooth)他を展示いたします。
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support" class="btn_blue">
										ご質問・ご招待券はこちら
									</LocalizedLink>
								</div>
							




							</div>
						</div>
					</div>
				</div>


			</>
		);
	};

	const OfficeEnInfo = () => {
		return (
			<>
				<div className="exhibition-page two-column-left-nav">
					<div className="main-content">
						<div className="content-container">
							<h1 className="exhibition_h1"> {exhibition} </h1>
							<div className="exhibition_inner">
								<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="https://www.foomajapan.jp/english/"
											className="exhibition_eventname_link"
										>
											FOOMA JAPAN 2022 (International Food Machinery &amp; Technology Exhibition)
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/mail" class="btn_gray">
											Click here for questions and pre-registration
										</LocalizedLink>
									</div>
								</div>

								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Name: 
												</div>
												<div className="exhibition-li-content medium">
													<a
														href="https://www.foomajapan.jp/english/"
														className="exhibition-li-content medium"
													>
														FOOMA JAPAN 2022 (International Food Machinery &amp; Technology Exhibition)
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Duration: 
												</div>
												<div className="exhibition-li-content medium">
													June 7, 2022 (Tuesday) - June 10, 2022 (Friday)
													<br />4 days from 10:00 to 17:00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Organizer: 
												</div>
												<div className="exhibition-li-content medium">
													Japan Food Machinery Manufacturers' Association
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Venue: 
												</div>
												<div className="exhibition-li-content medium">
													East Halls 1 thru 8, Tokyo Big Sight
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Our booth: 
												</div>
												<div className="exhibition-li-content medium">
													1K-20
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													Main exhibited products: 
												</div>
												<div className="exhibition-li-content medium">
													Waterproof digital thermometer with HACCP function, waterproof wireless temperature logger, 
													precision digital thermometer, food penetration therometer, CO2 monitor, sugar content meter, timer, etc.
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div class Name="exhibition-li-title">
													Admission: 
												</div>
												<div className="exhibition-li-content medium">
													Free; pre-registration via the organizer's website is required.
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
												This is an all-in-one exhibition of food machinery, materials,
												and equipment used at from food production sites to distribution and stores.
												<br />
												As well as the last time, we display various products to help people at every place in food industries,
												such as SK-270WP-B, a hand-sized penetration thermometer to transmit measured data to PC via Bluetooth, and
												SK-50CTH, a CO2 monitor to notify you when to ventilate in order to minimize the risk of Covid-19.
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support/mail" class="btn_blue">
										Click here for questions and pre-registration
									</LocalizedLink>
								</div>


							<div className="exhibition_title flex-container">
									<div className="exhibition_div_right">
										<a
											href="http://miruhakaru.jp/"
											className="exhibition_eventname_link"
										>
											Tokai Science Park 2022
										</a>
									</div>
									<div className="exhibition_div_left">
										<LocalizedLink to="/support/mail" class="btn_gray">
											Click here for questions and invitation tickets
										</LocalizedLink>
									</div>
								</div>

								<div className="flex-container exhibition_content">
									<div className="exhibition_content_right">
										<ul>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Name: </div>
												<div className="exhibition-li-content medium">
													<a
														href="http://miruhakaru.jp/"
														className="exhibition-li-content medium"
													>
														Tokai Science Park 2022
													</a>
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Duration: </div>
												<div className="exhibition-li-content medium">
													June 2, 2022 (Thursday) - June 4, 2022 (Saturday)
													<br />
													3 days from 10:00 to 17:00
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Organizers: </div>
												<div className="exhibition-li-content medium">
												Tokai Scientific Instruments Association, Aichi Prefectural Metrology Association, Sankei Shimbun Co., Ltd.
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Venue: </div>
												<div className="exhibition-li-content medium">
													Event Hall, Nagoya Congress Center
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Our booth: </div>
												<div className="exhibition-li-content medium">
													34
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">
													Main exhibited products: 
												</div>
												<div className="exhibition-li-content medium">
												Digital thermometer and thermohygrometer, wireless data logger, precision 2-ch temperature logger,
												CO2 monitor, infrared thermometer, pH meter, etc.
												</div>
											</li>
											<li className="exhibition-li flex row">
												<div className="exhibition-li-title">Admission: </div>
												<div className="exhibition-li-content medium">
													Free
												</div>
											</li>
										</ul>
										<div className="exhibition-contents">
											<p>
											    In addition to professionals, this general exhibition of laboratory equipment aims to attract children of
											    users and dealers who are unable to visit weekdays through stage shows for kids and their families on
											    Saturday to demonstrate how fun science is.
												<br />
												We display our brand new products such as SK-L753, a precision 2-ch logger with class A 4-wire Pt100
												sensors; SK-50CTH, a CO2 monitor to notify you when to ventilate as a countermeasure against infection;
												and SK-270WP-B, a wireless Bluetooth thermometer.
											</p>
										</div>
									</div>
								</div>
								<div className="exhibition_buttondiv">
									<LocalizedLink to="/support/mail" class="btn_blue">
										Click here for questions and pre-registration
									</LocalizedLink>
								</div>
							</div>
						</div>
					</div>
				</div>


			</>
		);
	};

	const pathName =
		typeof window !== "undefined" ? window.location.pathname : "";

	return (
		<>
			<article className="company-info two-column-left-nav">
				<ExhibitionLeftNav />
				<section className="main-content">
					{pathName.indexOf("/en") !== -1 ? <OfficeEnInfo /> : <OfficeJaInfo />}
				</section>
			</article>
			<Footer />
		</>
	);
};

export default Exhibition;
